import { Injectable, computed, inject } from '@angular/core';

import { Status } from '../enums/status.enum';
import { FeatureFlagService } from '../modules/feature-flag/feature-flag.service';
import { Models3dStoreService } from '../modules/toolbar/3dmodels/store/models3d.store.service';
import { BuildingCard } from '../modules/toolbar/favorites/favorites.interface';
import { FavoritesService } from '../modules/toolbar/favorites/favorites.service';

/**
 * Сервис для работы с карточками зданий.
 * @Injectable указывает, что этот сервис может быть внедрен в другие компоненты или сервисы.
 * @param {Object} providedIn - указывает, что сервис будет предоставлен в корневом модуле приложения.
 */
@Injectable({ providedIn: 'root' })
export class BuildingCardService {
  /**
   * Сервис для доступа к хранилищу моделей 3D.
   * @type {Models3dStoreService}
   */
  readonly #models3dStoreService = inject(Models3dStoreService);

  /**
   * Устанавливает сервис флагов функциональности для использования в коде.
   * @param {FeatureFlagService} FeatureFlagService - Сервис флагов функциональности.
   */
  readonly #featureFlagService = inject(FeatureFlagService);

  /**
   * Возвращает статус загрузки, вычисленный на основе статусов загрузки 3D моделей и избранных элементов.
   * @returns {Status} Статус загрузки (LOADED, LOADING, UNINITIALIZED)
   */
  readonly loadingStatus = computed(() => {
    const { LOADED, LOADING, UNINITIALIZED } = Status;

    if (this.#models3dStoreService.isLoading() || this.favoritesService.loadingStatus() === LOADING) {
      return LOADING;
    } else if (this.#models3dStoreService.isLoaded() && this.favoritesService.loadingStatus() === LOADED) {
      return LOADED;
    } else {
      return UNINITIALIZED;
    }
  });

  /**
   * Вычисляемое свойство, указывающее, завершен ли процесс загрузки успешно.
   * Значение определяется сравнением текущего статуса загрузки со значением перечисления `Status.LOADED`.
   *
   * @type {boolean}
   * @readonly
   */
  readonly isLoaded = computed(() => this.loadingStatus() === Status.LOADED);

  /**
   * Вычисляет список избранных зданий для существующего здания.
   * @returns {Array} Список избранных зданий для существующего здания.
   */
  favouritesForExistingBuilding = computed(() => {
    const favouriteEntities = this.favoritesService.favouriteEntities();
    const favorites = this.#models3dStoreService.models3dIds().reduce((acc, buildingId) => {
      if (favouriteEntities[buildingId]) {
        acc.push(favouriteEntities[buildingId]!);
      }

      return acc;
    }, [] as BuildingCard[]);

    const favoritesBuildingId = favorites.map((favorite) => favorite.buildingId);

    return this.favoritesService
      .favourites()
      .filter(
        (item) =>
          favoritesBuildingId.includes(item.buildingId) ||
          (this.#featureFlagService.isFeatureOn('FAVORITES_WITH_FOLDERS') ? item.address === undefined : item.address !== undefined),
      );
  });

  /**
   * Конструктор класса, принимающий сервисы для работы с флагами функциональности, 3D моделями и избранными элементами.
   *
   * @param {Models3dService} models3dService Сервис для работы с 3D моделями.
   * @param {FavoritesService} favoritesService Сервис для работы с избранными элементами.
   */
  constructor(private favoritesService: FavoritesService) {}

  /**
   * Загружает действия в зависимости от настроек приложения.
   * Если включена функциональность избранных элементов, вызывает метод getFavoritesAction() из favoritesService.
   * Если включена функциональность 3D моделей, вызывает метод getBuildings() из models3dService.
   * @returns {void}
   */
  loadAction(): void {
    this.favoritesService.getFavoritesAction();
    this.#models3dStoreService.getBuildings();
  }
}
