import { createAction, props } from '@ngrx/store';

import { BuildingCard } from '../favorites.interface';

export const getFavorites = createAction('[FAVORITES] Get Favorites');
export const getFavoritesSuccess = createAction('[FAVORITES] Get Favorites Success', props<{ buildingCards: BuildingCard[] }>());
export const addFavorite = createAction('[FAVORITES] Add Favorite', props<{ favorite: BuildingCard; skipRequest?: boolean }>());
export const addFavoriteSuccess = createAction('[FAVORITES] Add Favorite Success', props<{ favorite: BuildingCard }>());
export const removeFavorite = createAction('[FAVORITES] Delete Favorite', props<{ favorite: BuildingCard; skipRequest?: boolean }>());
export const removeManyFavorites = createAction(
  '[FAVORITES] Delete many Favorites',
  props<{
    favoriteIds: string[];
    skipRequest?: boolean;
  }>(),
);
export const replaceFavorites = createAction(
  '[FAVORITES] Replace Favorites',
  props<{ prevFavorites: BuildingCard[]; newFavorites: BuildingCard[]; skipRequest?: boolean }>(),
);
